const menuOptions = [{
  name: 'inicio',
  title: 'Inicio',
  path: '/'
},{
  name: 'productos',
  title: 'Productos',
  path: '/productos'
},{
  name: 'contacto',
  title: 'Contacto',
  path: '/contacto'
}]

export default menuOptions