import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'

import { letterSpacing, zIndex } from '../shared/styles'
import Grid from '../shared/Grid'
import Logo from '../shared/Logo'

import MobileMenu from './MobileMenu'
import menuOptions from './menuOptions'
import useWindowSize from '../../hooks/useWindowSize'

const WrapperStyles = css`
  position: sticky;
  top: 0;
  left: 0;
  height: auto;
  z-index: ${zIndex.z3};
`

const Wrapper = styled.header`
  ${WrapperStyles};
  background: transparent;
  padding: 25px 50px;
  transition: all 0.2s;
  
  &.active {
    ${WrapperStyles};
    background: rgba(0, 0, 0, 0.8);
    padding: 20px;
  }

  @media screen and (max-width: 1046px) {
    padding: 25px;
  }
`

const StyledLink = styled(Link)`
  color: white;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  margin: 0 30px;
  width: max-content;
  letter-spacing: ${letterSpacing.medium};
  transition: all 0.2s;

  &:hover {
    border-bottom: 1px solid;
  }

  span {
    margin-right: -5px;
    display: inline-block;
  }
`

const MenuWrapper = styled.nav`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: ${props => props.isActive ? '15px' : '18px'};
  transition: all 0.2s;
`

const NavBar = ({ isMenuOpen, setIsMenuOpen }) => {
  const [isActive, setIsActive] = useState(false)
  const isMobile = useWindowSize().width < 1046

  const activeStyle = {
    fontWeight: 'bolder',
    borderBottom: '1px solid',
  }

  const changeBackground = () => {
    if (window.scrollY >= 1) {
      setIsActive(true)
    } else {
      setIsActive(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', changeBackground)

    return () => window.removeEventListener('scroll', changeBackground);
  })

  if (isMobile) return (
    <Wrapper className={isActive ? 'active' : ''}>
    <Grid templateColumns="4fr 1fr" alignItems="center">
      <Logo height={isActive ? 30 : 35} isWhite={isActive} isBright={!isActive} to="/" />
      <MobileMenu isOpen={isMenuOpen} setIsOpen={setIsMenuOpen} />
    </Grid>
  </Wrapper>
  )

  return (
  <Wrapper className={isActive ? 'active' : ''}>
    <Grid templateColumns="1fr 1fr" alignItems="center">
      <Logo height={isActive ? 40 : 45} isWhite={isActive} isBright={!isActive} to='/' />
      <MenuWrapper isActive={isActive}>
        {menuOptions.map(
          ({ name, title, path }) => <StyledLink
            key={name}
            to={path}
            activeStyle={activeStyle}
            partiallyActive={name === 'productos'}
          ><span>{ title }</span></StyledLink>)}
      </MenuWrapper>
    </Grid>
  </Wrapper>
)}

NavBar.propTypes = {
  siteTitle: PropTypes.string,
}

NavBar.defaultProps = {
  siteTitle: `AgroCannabis`,
}

export default NavBar
