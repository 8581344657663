import React, { useCallback } from 'react'
import { Link } from 'gatsby'
import Burger from 'react-css-burger'
import styled from 'styled-components'

import { color, zIndex } from '../shared/styles'
import menuOptions from './menuOptions'
import Logo from '../shared/Logo'

const style = {
  marginRight: '10px',
  justifySelf: 'flex-end',
  alignSelf: 'center',
  position: 'absolute',
  zIndex: zIndex.z1,
  left: 'calc(100vw - 60px)'
}

const MenuWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: black;
  filter: blur(0.70);
  transition: all 0.2s;
  padding: 40px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  align-items: center;

  a {
    text-align: center;
    font-size: 25px;
    color: ${color.white};
    text-decoration: none;
    text-transform: uppercase;
    width: fit-content;
    justify-self: center;
    letter-spacing: 10px;
    display: inline-block;
    margin-right: -10px;

    &.active {
      color: #56a557;
      line-height: 40px;
    }
  }

  @media screen and (max-wdith: 1045px) {
    display: none;
  }
`

const MobileMenu = ({ isOpen, setIsOpen }) => {
  const handleClick = useCallback(() => {
    setIsOpen()
  }, [setIsOpen])

  return (
    <>
    {isOpen && (
      <MenuWrapper>
        {menuOptions.map(({ name, title, path }) => 
          <Link key={name} to={path} title={title} activeClassName="active">{ title }</Link>
        )}
        <Logo isBgCentered to="/" />
      </MenuWrapper>
    )}
      <Burger
        onClick={handleClick}
        active={isOpen}
        burger="vortex"
        color="white"
        hoverOpacity={1}
        scale={0.5}
        marginLeft={'0'}
        marginTop={'0'}
        style={style}
      />
    </>
    )
}

export default MobileMenu
