import styled from 'styled-components'

const Grid = styled.div`
  display: grid;
  grid-template-columns: ${(props) => props.templateColumns || '1fr'};
  grid-template-rows: ${(props) => props.templateRows || '1fr'};
  grid-gap: ${(props) => props.gridGap || '1px'};
  align-items: ${(props) => props.alignItems || 'flex-start'};
  justify-content: ${(props) => props.justifyContent || 'flex-start'};
`

export default Grid
