import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import { color, fontSize, letterSpacing } from '../shared/styles'

const Wrapper = styled.div`
  background: transparent;
  color: ${props => color[props.variant] || color.white};
  text-align: ${props => props.align || 'left'};
  
  a, button {
    cursor: pointer;
    border: 1px solid ${props => color[props.variant] || color.white};
    padding: 10px 50px;
    background: transparent;
    text-decoration: none;
    color: ${props => color[props.variant] || color.white};
    font-size: ${fontSize.sm};
    transition: all 0.2s;
    letter-spacing: ${letterSpacing.large};

    @media screen and (max-width: 660px) {
      padding: 10px 20px;
    }

    &:hover,
    &.hovered {
      background: ${props => color[props.variant] || color.white};
      color: ${props => {
        if (props.variant === 'black' || props.variant === 'green') {
          return color.white
        }
          return color.black
        }
      };
    }
  }
`

const Button = React.forwardRef(({ href, to, onClick, variant, children, align, isHovered = false }, ref) => {
  const content = () => {
    if (href) {
      return <a ref={ref} className={isHovered ? 'hovered' : ''} href={href} target="_blank" rel="noreferrer">{children}</a>
    }

    if (to) {
      return <Link ref={ref} className={isHovered ? 'hovered' : ''} to={to}>{children}</Link>
    }

    if (onClick) {
      return <button ref={ref} className={isHovered ? 'hovered' : ''} onClick={onClick}>{children}</button>
    }
  }
  
  return (
    <Wrapper variant={variant} align={align}>
      {content()}
    </Wrapper>
  )
})

export default Button
