import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import { navigate } from '@reach/router'

const Wrapper = styled.div`
  background: url(${({ bg }) => bg || ''});
  background-position: ${({ isBgCentered }) => isBgCentered ? 'center center' : '0 0'};
  background-repeat: no-repeat;
  background-size: contain;
  height: ${({ height }) => height ? height + 'px' : '64px'};
  width: 100%;
  filter: ${({ isWhite, isBright }) => {
    if (isWhite) return 'brightness(0) invert(1)'
    if (isBright) return 'brightness(1.5)'
    return 'none'
  }};
  opacity: ${({ opacity }) => opacity || '1'};
  border-right: ${({ borderRight }) => borderRight || 'none'};
  transition: all 0.2s;
  cursor: ${({ to }) => to ? 'pointer' : 'initial'};
`

const Logo = ({
  height,
  src = '',
  isWhite,
  opacity = 1,
  to,
  borderRight = 'none',
  isBright = false,
  isBgCentered = false
}) => {
  const data = useStaticQuery(graphql`
  {
    allSanitySiteSettings {
      nodes {
        id
        title
        logo {
          asset {
            url
          }
        }
      }
    }
  }
`)

  const logoSrc = data?.allSanitySiteSettings?.nodes[0]?.logo?.asset?.url

  const handleClick = () => {
    if (to) {
      navigate(to)
    }
  }

  return (
    <Wrapper
      bg={src || logoSrc}
      height={height}
      isWhite={isWhite}
      opacity={opacity}
      borderRight={borderRight}
      isBright={isBright}
      isBgCentered={isBgCentered}
      onClick={handleClick}
      to={to}
    />
  )
}

export default Logo
