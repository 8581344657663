/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { ToastContainer } from 'react-toastify';
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'react-toastify/dist/ReactToastify.css';
import '@fontsource/hind-siliguri'

import NavBar from './NavBar/NavBar'
import Footer from './Footer'
import { navbarHeight } from './shared/styles'
import './layout.css'

const Main = styled.main`
  margin-top: -${navbarHeight}px;
  top: 0;
  right: ${({ isMenuOpen }) => isMenuOpen ? '100%' : '0'};
  position: ${({ isMenuOpen }) => isMenuOpen ? 'relative' : 'static'};
  transition: all 0.4s;
`

const Layout = ({ children }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const data = useStaticQuery(graphql`
    {
      allSanityTelephones {
        nodes {
          name
          number
          id
        }
      }
      allSanitySocialMedia {
        edges {
          node {
            id
            name
            url
            icon {
              asset {
                url
              }
            }
          }
        }
      }
    }`
  )

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.position = 'fixed';
      document.body.style.top = `-${window.scrollY}px`;
    } else {
      const scrollY = document.body.style.top;
      document.body.style.position = '';
      document.body.style.top = '';
      window.scrollTo(0, parseInt(scrollY || '0') * -1);
    }
  }, [isMenuOpen])

  const phones = data?.allSanityTelephones?.nodes?.map(
    ({ name, number }) => ({ title: name, number })
  )

  const socialMedia = data?.allSanitySocialMedia?.edges?.map(
    ({ node: { id, name, url, icon: { asset: { url: icon } } } }) => ({ id, name, url, icon })
  )

  const handleMenuClick = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  return (
    <>
      <ToastContainer />
      <NavBar
        siteTitle={data?.site?.siteMetadata?.title || `Title`}
        isMenuOpen={isMenuOpen}
        setIsMenuOpen={handleMenuClick}
      />
      <Main isMenuOpen={isMenuOpen}>{children}</Main>
      <Footer phones={phones} socialMedia={socialMedia} />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
