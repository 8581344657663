const color = {
  black: '#222222',
  white: '#FFFFFF',
  green: '#2C823F',
  gray: '#E5E5E5',
  lighterGrey: '#F1F5F1',
  darkGray: '#838383',
  white40: 'rgba(255, 255, 255, 0.4)',
  white85: 'rgba(255, 255, 255, 0.85)',
}

const fontSize = {
  xs: '0.8rem',
  sm: '1rem',
  md: '2rem',
  lg: '3rem',
  xl: '4rem',
}

const lineHeight = {
  xs: '1.2rem',
  sm: '1.4rem',
  md: '2.4rem',
  lg: '3.4rem',
  xl: '4.4rem'
}

const zIndex = {
  z1: 100,
  z2: 200,
  z3: 300,
  z4: 400,
  z5: 500,
}

const fontFamily = {
  hind: 'Hind Siliguri',
}

const letterSpacing = {
  medium: '0.25em',
  large: '0.3em',
}

const navbarHeight = 114;

export {
  color,
  fontSize,
  zIndex,
  navbarHeight,
  fontFamily,
  lineHeight,
  letterSpacing
}
