import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons'

import { color, fontSize } from '../shared/styles'
import useWindowSize from '../../hooks/useWindowSize'
import getTelephoneNumber from '../../utils/getTelephoneNumber'
import Logo from '../shared/Logo'

const Wrapper = styled.footer`
  padding: 50px 100px;
  display: grid;
  grid-template-columns: 3fr 3fr 3fr 1fr;
  background-color: ${color.black};
  color: ${color.white};
  min-height: 200px;
  width: 100%;
  align-items: center;
  grid-gap: 50px;

  div:nth-child(2) {
    border-left: 1px solid ${color.white40};
    padding-left: 50px;
    height: 100%;

    @media screen and (max-width: 940px) {
      padding-left: 0;
      border-left: none;
    }
  }

  @media screen and (max-width: 940px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 45px;
    padding: 50px;

    div:nth-child(1) {
      grid-column: 1/3;
    }

    div:nth-child(4) {
      grid-column: 1/3;
    }
  }

  @media screen and (max-width: 530px) {
    grid-template-columns: 1fr;

    div:nth-child(1) {
      grid-column: auto;
    }

    div:nth-child(4) {
      grid-column: auto;
    }
  }
`

const InformationBlock = styled.div`
  display: flex;
  flex-direction: column;
  color: ${color.white};
  align-items: ${({ align }) => align || 'flex start'};

  @media screen and (max-width: 940px) {
    text-align: center;
  }

  p {
    font-size: ${fontSize.xs};
    margin-bottom: 0;
  }

  a {
    color: ${color.white};
    text-decoration: none;
  }
`

const SocialMedia = styled.a`
  text-decoration: none;
  width: ${props => props.size || '50px'};
  height: ${props => props.size || '50px'};
  background-image: url(${(props => props.iconUrl || '')});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  filter: ${(props) => props.isWhite ? 'brightness(0) invert(1)' : 'none'};
`

const Footer = ({ phones, socialMedia }) => {
  const isMobile = useWindowSize().width < 940;

  return (
    <Wrapper>
      <Logo
        isWhite
        opacity={0.4}
        height={40}
        isBgCentered={isMobile}
        to="/"
      />
      <InformationBlock>
        <h4>Dirección</h4>
        <p>Ruta 55, km 23.500.<br />Ombúes de Lavalle</p>
      </InformationBlock>
      {phones && (
        <InformationBlock>
          <h4>Teléfonos</h4>
          {phones.map(
            phone => <p key={phone.title}>
              <a href={phone.title === 'Llamadas' ? `tel:+${getTelephoneNumber(phone.number)}` : `https://wa.me/${getTelephoneNumber(phone.number)}`}>
                <FontAwesomeIcon icon={phone.title.toLowerCase() === 'whatsapp' ? faWhatsapp : faPhoneAlt} /> {phone.number}
              </a>
            </p>)}
        </InformationBlock>
      )}
      {socialMedia && (
        <InformationBlock align={isMobile ? 'center' : 'flex-end'}>
          {socialMedia.map(social =>
            <SocialMedia
              key={social.id}
              href={social.url}
              target="_blank"
              iconUrl={social.icon}
              size="25px"
              isWhite
            />
          )}
        </InformationBlock>
      )}
    </Wrapper>
  )
}

export default Footer
